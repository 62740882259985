import { useEffect } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { colors } from '../../utils';
import { Questions, Question, Users, User } from '../dashboard';
import { Login } from '../auth';
import { Header } from '../../components';

const { Content } = Layout;

const Main = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token') ?? '';

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token]);

  return token ? <LoggedInRoutes /> : <NotLoggedInRoutes />;
};

const LoggedInRoutes = () => {
  return (
    <Layout
      hasSider
      style={{ minHeight: '100vh', backgroundColor: colors.background }}
    >
      <Layout style={{ gap: 16, padding: 16 }}>
        <Header />
        <Content>
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  colorBgContainer: colors.white,
                  colorBorder: colors.defaultBorderColor,
                  activeBorderColor: colors.defaultBorderColor,
                  hoverBg: colors.white,
                  activeBg: colors.white,
                },
                Select: {
                  colorBgContainer: colors.white,
                  colorBorder: colors.defaultBorderColor,
                },
              },
            }}
          >
            <Routes>
              <Route path={'/'} element={<Users />} />
              <Route path={'/users'} element={<Users />} />
              <Route path={'/users/:id'} element={<User />} />
              <Route path={'/questions'} element={<Questions />} />
              <Route path={'/questions/:id'} element={<Question />} />
              <Route path={'/questions/create'} element={<Question />} />
              <Route path={'*'} element={<Users />} />
            </Routes>
          </ConfigProvider>
        </Content>
      </Layout>
    </Layout>
  );
};

const NotLoggedInRoutes = () => {
  return (
    <Layout style={{ minHeight: '100vh', background: colors.background }}>
      <Routes>
        <Route path={'*'} element={<Login />} />
      </Routes>
    </Layout>
  );
};

export default Main;
