import {
  Breadcrumb,
  Card,
  Col,
  ConfigProvider,
  Popconfirm,
  Row,
  Spin,
  Tag,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  BLACKLIST_USER,
  DELETE_USER,
  GET_USER_BY_ID,
  GET_USERS,
  Mutation,
  MutationBlacklistUserArgs,
  MutationPermanentlyDeleteUserArgs,
  Query,
  QueryGetUserByIdArgs,
  User,
  UserStatus,
} from '../../../graphql';
import { useEffect, useState } from 'react';
import { ErrorButton, SecondaryButton, Text } from '../../../components';
import { colors } from '../../../utils';
import { format } from 'date-fns';

export default function SingleUser() {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const [user, setUser] = useState<User>();
  const { data, loading } = useQuery<Query, QueryGetUserByIdArgs>(
    GET_USER_BY_ID,
    {
      variables: { userId: userId as string },
    }
  );
  const [deleteUser, { loading: deleting }] = useMutation<
    Mutation,
    MutationPermanentlyDeleteUserArgs
  >(DELETE_USER);
  const [blacklistUser, { loading: blacklisting }] = useMutation<
    Mutation,
    MutationBlacklistUserArgs
  >(BLACKLIST_USER);

  useEffect(() => {
    if (data?.getUserById) {
      setUser(data.getUserById);
    }
  }, [data]);

  const handleDelete = () => {
    if (!!userId)
      deleteUser({
        variables: { userId },
        refetchQueries: [{ query: GET_USERS }],
      }).then(() => {
        navigate(-1);
      });
  };

  const handleBlacklist = () => {
    if (!!userId)
      blacklistUser({
        variables: {
          userId,
          blacklist: user?.status !== UserStatus.Blacklisted,
        },
        refetchQueries: [{ query: GET_USER_BY_ID, variables: { userId } }],
      }).then(() => {
        // navigate(-1);
      });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
            activeBorderColor: colors.defaultBorderColor,
            hoverBg: colors.white,
            activeBg: colors.white,
          },
          Select: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
          },
        },
      }}
    >
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Card>
            {loading ? (
              <Row
                justify={'center'}
                align={'middle'}
                style={{ padding: '6em' }}
              >
                <Spin />
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  <Row align={'middle'} justify={'space-between'}>
                    <Col>
                      <Breadcrumb>
                        <Breadcrumb.Item>All users</Breadcrumb.Item>
                        <Breadcrumb.Item>{user?.username}</Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>
                    <Col>
                      <Row gutter={12}>
                        <Col>
                          <SecondaryButton
                            loading={blacklisting}
                            onClick={handleBlacklist}
                          >
                            {user?.status === UserStatus.Blacklisted
                              ? 'Reactivate user'
                              : 'Blacklist user'}
                          </SecondaryButton>
                        </Col>
                        <Col>
                          <Popconfirm
                            icon={false}
                            placement={'bottomLeft'}
                            title={'Are you sure you want to delete this user?'}
                            okText={'Yes'}
                            okButtonProps={{
                              style: { background: colors.primary },
                            }}
                            onConfirm={handleDelete}
                          >
                            <ErrorButton loading={deleting}>Delete</ErrorButton>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ marginTop: 12 }}>
                  <Text fontSize={16} fontWeight={600}>
                    User details
                  </Text>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <Row gutter={[16, 20]}>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Status
                      </Text>
                      <Tag
                        style={{ width: 'fit-content' }}
                        color={
                          user?.status === UserStatus.Blacklisted
                            ? 'error'
                            : 'blue'
                        }
                      >
                        {user?.status}
                      </Tag>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Joined
                      </Text>
                      <Text fontSize={16}>
                        {user?.createdAt
                          ? format(new Date(user?.createdAt), 'PP')
                          : '-'}
                      </Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Telegram username
                      </Text>
                      <Text fontSize={16}>{user?.username}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Name
                      </Text>
                      <Text fontSize={16}>{user?.name || '-'}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Email
                      </Text>
                      <Text fontSize={16}>{user?.email || '-'}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Wallet address
                      </Text>
                      <Text fontSize={16}>{user?.address || '-'}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Price score
                      </Text>
                      <Text fontSize={16}>{user?.priceScore}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Power score
                      </Text>
                      <Text fontSize={16}>{user?.horsePowerScore}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Referral score
                      </Text>
                      <Text fontSize={16}>{user?.referralScore}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Referral count
                      </Text>
                      <Text fontSize={16}>{user?.referralCount}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Referral code
                      </Text>
                      <Text fontSize={16}>{user?.code}</Text>
                    </Col>
                    <Col style={{ display: 'grid' }} xs={24} md={12}>
                      <Text fontSize={12} color={'black5'}>
                        Used referral code
                      </Text>
                      <Text fontSize={16}>
                        {user?.usedReferralCode?.code || '-'}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
}
