import { Card, Col, Layout, Row } from 'antd';
import { colors } from '../utils';
import { Text } from './Text';
import { useEffect, useMemo, useState } from 'react';
import { PrimaryButton } from './Button';
import { useNavigate } from 'react-router-dom';

const { Header: AntHeader } = Layout;

type Tab = 'Users' | 'Questions';

const Header = () => {
  const navigate = useNavigate();
  const tabs: Tab[] = useMemo(() => ['Users', 'Questions'], []);
  const [selectedTab, setSelectedTab] = useState<Tab>('Users');

  useEffect(() => {
    if (location.pathname.includes('users')) {
      setSelectedTab('Users');
    } else if (location.pathname.includes('questions')) {
      setSelectedTab('Questions');
    } else {
      setSelectedTab('Users');
    }
  }, [location.pathname]);

  return (
    <AntHeader
      style={{
        height: 'auto',
        backgroundColor: colors.background,
        padding: 0,
      }}
    >
      <Row>
        <Card
          className={'card-no-padding'}
          style={{ width: '100%', padding: 16 }}
        >
          <Row justify={'space-between'} align={'middle'}>
            <Col className={'clickable'} onClick={() => navigate('/')}>
              <Row gutter={12} align={'middle'}>
                <Col>
                  <Text fontSize={20} fontWeight={600}>
                    CryptoAutos
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={36} align={'middle'}>
                <Col>
                  <Row gutter={8}>
                    {tabs.map((tab) => (
                      <Col>
                        <Card
                          onClick={() => {
                            setSelectedTab(tab);
                            navigate(`/${tab.toLowerCase()}`);
                          }}
                          className={'card-no-padding clickable'}
                          style={{
                            padding: '9px 20px',
                            border: 'none',
                            borderRadius: 12,
                            background:
                              selectedTab === tab ? colors.blue1 : 'white',
                          }}
                        >
                          <Text
                            color={selectedTab === tab ? 'blue6' : 'black5'}
                          >
                            {tab}
                          </Text>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={() => {
                      localStorage.clear();
                      navigate('/login');
                    }}
                  >
                    Logout
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Row>
    </AntHeader>
  );
};

export default Header;
