import {
  Card,
  Col,
  Flex,
  Input,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { Text } from '../../../components';
import { useQuery } from '@apollo/client';
import {
  Query,
  User,
  GET_USERS,
  QueryGetUsersArgs,
  UserStatus,
} from '../../../graphql';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import { useState } from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

type SortType = 'price' | 'power' | 'referral';

const Users = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<SortType | ''>('');
  const [status, setStatus] = useState<UserStatus>();
  const { data, loading } = useQuery<Query, QueryGetUsersArgs>(GET_USERS, {
    variables: { page, take: 10, search, sortBy, status },
    fetchPolicy: 'network-only',
  });

  const debouncedSearch = debounce((searchTerm: string) => {
    setSearch(searchTerm);
  }, 500);

  const columns: ColumnsType<User> = [
    {
      key: 'username',
      title: 'Username',
      dataIndex: 'username',
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      responsive: ['lg'],
      render: (email) => email ?? '-',
    },
    {
      key: 'address',
      title: 'Wallet address',
      dataIndex: 'address',
      responsive: ['lg'],
      render: (address) => (
        <Tooltip title={address}>
          {address
            ? address.slice(0, 6) +
              '...' +
              address.slice(address.length - 4, address.length - 1)
            : '-'}
        </Tooltip>
      ),
    },
    {
      key: 'priceScore',
      title: 'Price score',
      dataIndex: 'priceScore',
    },
    {
      key: 'powerScore',
      title: 'Power score',
      dataIndex: 'horsePowerScore',
    },
    {
      key: 'referralScore',
      title: 'Referral score',
      dataIndex: 'referralScore',
    },
    {
      key: 'code',
      title: 'Referral code',
      responsive: ['lg'],
      dataIndex: 'code',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (item) => (
        <Tag color={item === UserStatus.Blacklisted ? 'error' : 'blue'}>
          {item}
        </Tag>
      ),
    },
    {
      key: 'createdAt',
      title: 'Joined',
      dataIndex: 'createdAt',
      responsive: ['lg'],
      render: (item) => format(new Date(item), 'PP'),
    },
  ];

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={500}>
            All users
          </Text>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12} lg={8}>
              <Input
                placeholder={'Type to search'}
                value={searchValue}
                onChange={(e) => {
                  if (page !== 1) setPage(1);
                  setSearchValue(e.target.value);
                  debouncedSearch(e.target.value);
                }}
              />
            </Col>
            <Col xs={0} md={12} lg={8}>
              <Select
                allowClear
                onChange={setStatus}
                style={{ width: '100%', textTransform: 'capitalize' }}
                placeholder={'Select status'}
              >
                {[UserStatus.Active, UserStatus.Blacklisted].map((status) => (
                  <Select.Option
                    value={status}
                    key={status}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {status.toLowerCase()}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={0} lg={8} />
            <Col xs={24} md={12} lg={8}>
              <Flex gap={12}>
                <Text style={{ whiteSpace: 'nowrap' }}>Sort by:</Text>
                <Radio.Group
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  {(['price', 'power', 'referral'] as SortType[]).map(
                    (item: SortType) => (
                      <Radio
                        value={item}
                        onClick={() =>
                          setSortBy((prev) => (prev === item ? '' : item))
                        }
                      >
                        {item[0].toUpperCase() + item.slice(1)} score
                      </Radio>
                    )
                  )}
                </Radio.Group>
              </Flex>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            loading={loading}
            columns={columns}
            onRow={(record) => ({
              onClick: () => navigate(`/users/${record.id}`),
            })}
            dataSource={data?.getUsers?.data}
            rowClassName={'clickable'}
            pagination={{
              pageSize: 10,
              total: data?.getUsers.count,
              current: page,
              onChange: (page) => setPage(page),
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Users;
