import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query getUsers(
    $page: Float
    $take: Float
    $sortBy: String
    $status: String
    $search: String
  ) {
    getUsers(
      page: $page
      take: $take
      sortBy: $sortBy
      status: $status
      search: $search
    ) {
      data {
        id
        status
        username
        name
        email
        address
        horsePowerScore
        priceScore
        referralScore
        code
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($userId: String!) {
    getUserById(userId: $userId) {
      id
      status
      username
      name
      email
      address
      horsePowerScore
      priceScore
      referralScore
      code
      referralCount
      usedReferralCode {
        id
        code
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_QUESTIONS = gql`
  query GetQuestions($page: Float, $take: Float) {
    getQuestions(page: $page, take: $take) {
      data {
        id
        name
        image
        price
        horsepower
      }
      count
    }
  }
`;
