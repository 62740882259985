import {
  Breadcrumb,
  Card,
  Col,
  ConfigProvider,
  Input,
  message,
  Popconfirm,
  Row,
} from 'antd';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  GET_QUESTIONS,
  Mutation,
  MutationCreateQuestionArgs,
  MutationDeleteQuestionArgs,
  MutationUpdateQuestionArgs,
  MutationUploadImageArgs,
  Question,
  QuestionToEdit,
  UPDATE_QUESTION,
  UPLOAD_FILE,
  uploadClient,
} from '../../../graphql';
import { useEffect, useState } from 'react';
import {
  ErrorButton,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '../../../components';
import { colors } from '../../../utils';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile, UploadProps } from 'antd/es/upload';

export default function SingleQuestion() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isCreate = useMatch('/questions/create');
  const questionToEdit = useReactiveVar(QuestionToEdit);
  const [question, setQuestion] = useState<Question>();
  const [filePreview, setFilePreview] = useState<any>();
  const [createQuestion, { loading: creating }] = useMutation<
    Mutation,
    MutationCreateQuestionArgs
  >(CREATE_QUESTION);
  const [updateQuestion, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateQuestionArgs
  >(UPDATE_QUESTION);
  const [deleteQuestion, { loading: deleting }] = useMutation<
    Mutation,
    MutationDeleteQuestionArgs
  >(DELETE_QUESTION);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadImageArgs
  >(UPLOAD_FILE, { client: uploadClient });

  useEffect(() => {
    return () => {
      setQuestion(undefined);
      setFilePreview(undefined);
      QuestionToEdit(undefined);
    };
  }, []);

  useEffect(() => {
    if (state?.question) {
      setQuestion(state.question);
    }
  }, [state]);

  const handleDelete = () => {
    if (!!question)
      deleteQuestion({
        variables: { id: question.id },
        refetchQueries: [{ query: GET_QUESTIONS }],
      }).then((res) => {
        navigate(-1);
      });
  };

  const handleFileUpload = (image: RcFile) => {
    return uploadFile({
      variables: {
        image,
        featureName: 'question',
      },
    });
  };

  const handleUpdate = () => {
    if (!!question)
      if (questionToEdit?.file) {
        handleFileUpload(questionToEdit.file).then((res) => {
          updateQuestion({
            variables: {
              id: question.id,
              questionInput: {
                name: questionToEdit?.name,
                price: questionToEdit?.price,
                horsepower: questionToEdit?.horsepower,
                // @ts-ignore
                image: res?.data?.uploadImage ?? '',
              },
            },
            refetchQueries: [{ query: GET_QUESTIONS }],
          }).then((res) => {
            setQuestion({
              ...question,
              ...res.data?.updateQuestion,
            });
            setFilePreview(undefined);
            QuestionToEdit(undefined);
          });
        });
      } else {
        updateQuestion({
          variables: {
            id: question.id,
            questionInput: {
              name: questionToEdit?.name,
              price: questionToEdit?.price,
              horsepower: questionToEdit?.horsepower,
            },
          },
          refetchQueries: [{ query: GET_QUESTIONS }],
        }).then((res) => {
          setQuestion({
            ...question,
            ...res.data?.updateQuestion,
          });
          setFilePreview(undefined);
          QuestionToEdit(undefined);
        });
      }
  };

  const handleCreate = () => {
    if (
      !questionToEdit?.name ||
      !questionToEdit.price ||
      !questionToEdit.horsepower ||
      !questionToEdit.file
    ) {
      message.error('Please fill all fields!');
    } else {
      handleFileUpload(questionToEdit.file).then((res) => {
        createQuestion({
          variables: {
            questionInput: {
              name: questionToEdit.name as string,
              price: questionToEdit.price as number,
              horsepower: questionToEdit.horsepower as number,
              image: res?.data?.uploadImage ?? '',
            },
          },
          refetchQueries: [{ query: GET_QUESTIONS }],
        }).then((res) => {
          setQuestion({
            ...question,
            ...(res.data?.createQuestion as Question),
          });
          QuestionToEdit(undefined);
          setFilePreview(undefined);
        });
      });
    }
  };

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    accept: 'image/*',
    // @ts-ignore
    action: (file) => {
      QuestionToEdit({
        ...questionToEdit,
        file,
      });
      previewImage(file);
    },
    showUploadList: false,
  } as UploadProps;

  const previewImage = (file: any) => {
    var oFReader = new FileReader();
    oFReader.readAsDataURL(file);

    oFReader.onload = function (oFREvent) {
      setFilePreview(oFREvent?.target?.result);
    };
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
            activeBorderColor: colors.defaultBorderColor,
            hoverBg: colors.white,
            activeBg: colors.white,
          },
          Select: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
          },
        },
      }}
    >
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Row align={'middle'} justify={'space-between'}>
                  <Col>
                    {isCreate ? (
                      <Text fontSize={16} fontWeight={600}>
                        Create question
                      </Text>
                    ) : (
                      <Breadcrumb>
                        <Breadcrumb.Item>All questions</Breadcrumb.Item>
                        <Breadcrumb.Item>{question?.name}</Breadcrumb.Item>
                      </Breadcrumb>
                    )}
                  </Col>
                  <Col>
                    {isCreate ? (
                      <Row gutter={12}>
                        <Col>
                          <SecondaryButton
                            onClick={() => {
                              setFilePreview(undefined);
                              QuestionToEdit(undefined);
                              navigate(-1);
                            }}
                          >
                            Cancel
                          </SecondaryButton>
                        </Col>
                        <Col>
                          <PrimaryButton
                            onClick={handleCreate}
                            loading={creating || uploading}
                          >
                            Save
                          </PrimaryButton>
                        </Col>
                      </Row>
                    ) : !!questionToEdit?.name ? (
                      <Row gutter={12}>
                        <Col>
                          <SecondaryButton
                            onClick={() => {
                              QuestionToEdit(undefined);
                              setFilePreview(undefined);
                            }}
                          >
                            Cancel
                          </SecondaryButton>
                        </Col>
                        <Col>
                          <PrimaryButton
                            onClick={handleUpdate}
                            loading={updating || uploading}
                          >
                            Save
                          </PrimaryButton>
                        </Col>
                      </Row>
                    ) : (
                      <Row gutter={12}>
                        <Col>
                          <SecondaryButton
                            onClick={() =>
                              QuestionToEdit({
                                name: question?.name,
                                image: question?.image,
                                price: question?.price,
                                horsepower: question?.horsepower,
                              })
                            }
                          >
                            Edit
                          </SecondaryButton>
                        </Col>
                        <Col>
                          <Popconfirm
                            icon={false}
                            placement={'bottomLeft'}
                            title={'Are you sure you want to delete this?'}
                            okText={'Yes'}
                            okButtonProps={{
                              style: { background: colors.primary },
                            }}
                            onConfirm={handleDelete}
                          >
                            <ErrorButton loading={deleting}>Delete</ErrorButton>
                          </Popconfirm>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={isCreate ? 0 : 24} style={{ marginTop: 12 }}>
                <Text fontSize={16} fontWeight={600}>
                  {!!questionToEdit ? 'Edit question' : 'Question details'}
                </Text>
              </Col>
              <Col md={24} lg={12} style={{ marginTop: 10 }}>
                <Row gutter={[16, 20]}>
                  <Col style={{ display: 'grid' }} xs={24}>
                    <Text fontSize={12} color={'black5'}>
                      Item name
                    </Text>
                    {!!questionToEdit || isCreate ? (
                      <Input
                        value={questionToEdit?.name ?? ''}
                        onChange={(e) =>
                          QuestionToEdit({
                            ...questionToEdit,
                            name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <Text fontSize={16}>{question?.name}</Text>
                    )}
                  </Col>
                  <Col style={{ display: 'grid' }} xs={24} md={12}>
                    <Text fontSize={12} color={'black5'}>
                      Price
                    </Text>
                    {!!questionToEdit || isCreate ? (
                      <Input
                        type={'number'}
                        value={questionToEdit?.price ?? ''}
                        onChange={(e) =>
                          QuestionToEdit({
                            ...questionToEdit,
                            price: Number(e.target.value),
                          })
                        }
                      />
                    ) : (
                      <Text fontSize={16}>{question?.price}</Text>
                    )}
                  </Col>
                  <Col style={{ display: 'grid' }} xs={24} md={12}>
                    <Text fontSize={12} color={'black5'}>
                      Horsepower
                    </Text>
                    {!!questionToEdit || isCreate ? (
                      <Input
                        type={'number'}
                        value={questionToEdit?.horsepower ?? ''}
                        onChange={(e) =>
                          QuestionToEdit({
                            ...questionToEdit,
                            horsepower: Number(e.target.value),
                          })
                        }
                      />
                    ) : (
                      <Text fontSize={16}>{question?.horsepower}</Text>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={24} style={{ marginTop: 10 }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Row gutter={[8, 4]}>
                      <Col span={24}>
                        <Text fontSize={12} color={'black5'}>
                          Image
                        </Text>
                      </Col>
                      <Col span={12}>
                        {!!questionToEdit || isCreate ? (
                          <Dragger
                            {...uploadProps}
                            style={{
                              padding: '0 8px',
                              minHeight: 120,
                              background: `${colors.black1}88`,
                              borderColor: colors.black5,
                            }}
                          >
                            {filePreview ? (
                              <img
                                id="blah"
                                src={filePreview}
                                alt=""
                                style={{
                                  height: 300,
                                  maxWidth: '100%',
                                  objectFit: 'contain',
                                  borderRadius: 4,
                                }}
                              />
                            ) : questionToEdit?.image ? (
                              <img
                                id="blah"
                                src={questionToEdit?.image}
                                alt=""
                                style={{
                                  height: 300,
                                  maxWidth: '100%',
                                  objectFit: 'contain',
                                  borderRadius: 4,
                                }}
                              />
                            ) : null}
                          </Dragger>
                        ) : (
                          <img
                            src={question?.image}
                            alt={''}
                            style={{
                              height: 300,
                              maxWidth: '100%',
                              objectFit: 'contain',
                              borderRadius: 4,
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
}
