export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  blacklistUser: User;
  createQuestion: Question;
  deleteQuestion: Scalars['Boolean']['output'];
  increaseScore: User;
  loginAdmin: LoginResponse;
  permanentlyDeleteUser: Scalars['Boolean']['output'];
  refreshAccessToken: TokenType;
  register: LoginResponse;
  updateQuestion: Question;
  updateReferralCode: User;
  updateUser: Scalars['Boolean']['output'];
  uploadImage: Scalars['String']['output'];
};


export type MutationBlacklistUserArgs = {
  blacklist: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCreateQuestionArgs = {
  questionInput: QuestionInput;
};


export type MutationDeleteQuestionArgs = {
  id: Scalars['String']['input'];
};


export type MutationIncreaseScoreArgs = {
  scoreToAdd: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationLoginAdminArgs = {
  loginInput: LoginInput;
};


export type MutationPermanentlyDeleteUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  userData: UserInput;
};


export type MutationUpdateQuestionArgs = {
  id: Scalars['String']['input'];
  questionInput: UpdateQuestionInput;
};


export type MutationUpdateReferralCodeArgs = {
  code: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  userUpdate: UpdateUserInput;
};


export type MutationUploadImageArgs = {
  featureName: Scalars['String']['input'];
  image: Scalars['Upload']['input'];
};

/** Questions */
export type PaginatedQuestions = {
  __typename?: 'PaginatedQuestions';
  count: Scalars['Float']['output'];
  data: Array<Question>;
};

/** Paginated Users */
export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  count: Scalars['Float']['output'];
  data: Array<User>;
};

export type Query = {
  __typename?: 'Query';
  getHorsePowerLeaderboard: Array<User>;
  getHorsePowerRank: RankDetails;
  getPriceLeaderboard: Array<User>;
  getPriceRank: RankDetails;
  getQuestions: PaginatedQuestions;
  getUser: User;
  getUserById: User;
  getUsers: PaginatedUsers;
  ping: Scalars['String']['output'];
};


export type QueryGetQuestionsArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetUserByIdArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUsersArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};

export type Question = {
  __typename?: 'Question';
  horsepower: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type QuestionInput = {
  horsepower: Scalars['Float']['input'];
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

/** Rank Details */
export type RankDetails = {
  __typename?: 'RankDetails';
  rank: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type TokenType = {
  __typename?: 'TokenType';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type UpdateQuestionInput = {
  horsepower?: InputMaybe<Scalars['Float']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  email?: Maybe<Scalars['String']['output']>;
  horsePowerScore: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  lastPlayDay?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  playCount: Scalars['Float']['output'];
  priceScore: Scalars['Float']['output'];
  referralCount?: Maybe<Scalars['Float']['output']>;
  referralScore: Scalars['Float']['output'];
  role: UserRole;
  status: UserStatus;
  telegramId: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  usedBy?: Maybe<Array<User>>;
  usedReferralCode?: Maybe<User>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  telegramId: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Player = 'PLAYER'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Blacklisted = 'BLACKLISTED'
}
