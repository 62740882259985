import { Card, Col, Row, Table } from 'antd';
import { PrimaryButton, Text } from '../../../components';
import { useQuery } from '@apollo/client';
import {
  Query,
  GET_QUESTIONS,
  Question,
  QueryGetQuestionsArgs,
} from '../../../graphql';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Questions = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery<Query, QueryGetQuestionsArgs>(
    GET_QUESTIONS,
    { variables: { page, take: 10 }, fetchPolicy: 'network-only' }
  );

  const columns: ColumnsType<Question> = [
    {
      key: 'name',
      title: 'Item',
      dataIndex: 'name',
    },
    {
      key: 'price',
      title: 'Price',
      dataIndex: 'price',
    },
    {
      key: 'horsepower',
      title: 'Horsepower',
      dataIndex: 'horsepower',
    },
  ];

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Row justify={'space-between'}>
            <Text fontSize={20} fontWeight={500}>
              All questions
            </Text>
            <PrimaryButton onClick={() => navigate('/questions/create')}>
              Add question
            </PrimaryButton>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            loading={loading}
            columns={columns}
            dataSource={data?.getQuestions?.data}
            rowClassName={'clickable'}
            onRow={(record) => ({
              onClick: () =>
                navigate(`/questions/${record.id}`, {
                  state: { question: record },
                }),
            })}
            pagination={{
              pageSize: 10,
              total: data?.getQuestions?.count,
              current: page,
              onChange: (page) => setPage(page),
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Questions;
